import React from 'react'
import { Link } from 'gatsby'
import { css } from 'linaria'
import rhythm from '../../utils/rhythm'
import { colors, transition } from '../../utils/theme'
import { ReactComponent as ArrowRight } from '../../assets/svg/icons/arrow-right.svg'
import breakpoints from '../../utils/tokens/breakpoints'

const link = css`
  color: ${colors.accent.purple};
  text-decoration: none;

  svg {
  }

  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
`

const arrow = css`
  margin-left: ${rhythm(1)};
  transition: transform ${transition.speed.l} ${transition.curve.scaleUp};
`

const heading = css`
  text-align: left;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: ${rhythm(3 / 2)};
  }

  @media (min-width: ${breakpoints.xl}) {
    margin-bottom: ${rhythm(3)};
  }
`

const PostsSectionHeader = ({ text, to }) => (
  <>
    <h2 className={heading}>
      {to ? (
        <Link to={to} className={link}>
          {text} <ArrowRight className={arrow} />
        </Link>
      ) : (
        text
      )}
    </h2>
  </>
)

export default PostsSectionHeader
