import React from 'react'
import Img from '../NonStretchedImage'
import { Link } from 'gatsby'
import { css } from 'linaria'
import rhythm from '../../utils/rhythm'
import { colors } from '../../utils/theme'
import { Lead, HeroHeading } from '../TextElements'
import delve from 'dlv'
import breakpoints from '../../utils/tokens/breakpoints'

const container = css`
  display: grid;
  align-items: center;
  grid-template-columns: 100%;
  padding-top: ${rhythm(3 / 2)};

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr 30%;
    column-gap: ${rhythm(1)};
    align-items: flex-start;
  }

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: auto 445px;
    padding: ${rhythm(3.75)} 4vw ${rhythm(2)};
    column-gap: 10vw;
    align-items: center;
  }

  @media (min-width: ${breakpoints.xl}) {
    padding-right: ${rhythm(5)};
    padding-left: ${rhythm(5)};
  }

  @media (min-width: ${breakpoints.xxl}) {
    column-gap: ${rhythm(6)};
  }
`

const title = css`
  text-decoration: none;
  color: ${colors.accent.purple};
`

const cover = css`
  border-radius: 8px;
  overflow: hidden;
`

const FeaturedPost = props => (
  <article className={container}>
    <div>
      <HeroHeading>
        <Link to={props.fields.slug} className={title}>
          {props.frontmatter.title}
        </Link>
      </HeroHeading>
      <Lead
        className={css`
          ${{ marginTop: 0 }};
        `}
      >
        {props.frontmatter.excerpt}
      </Lead>
    </div>
    {delve(props, 'frontmatter.image.childImageSharp.fluid') && (
      <div>
        <Link to={props.fields.slug}>
          <Img
            fluid={props.frontmatter.image.childImageSharp.fluid}
            className={cover}
          />
        </Link>
      </div>
    )}
  </article>
)

export default FeaturedPost
